export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        quotations :'', 
        quotation :'', 
    },
    
    getters : {
        quotations: (state )=> state.quotations,
        quotation: (state )=> state.quotation,
       
    },


    mutations : { 
        setquotations :(state,quotations ) => (state.quotations = quotations), 
        setquotation :(state,quotation ) => (state.quotation = quotation), 
    },

    actions : {

        async create_quotation( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_quotation')
            return new Promise((resolve, reject) => {
            axios.post("/manager/quotations/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setquotations', response.data.res)
                toast.success("quotation added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_quotations( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_quotations')
            return new Promise((resolve, reject) => {
            axios.post("/manager/quotations?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setquotations', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_quotation( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_quotation' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/quotations/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setquotation', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_quotation( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_quotation' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/quotations/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setquotations', response.data.res)
                toast.success("quotation updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_quotation_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/quotations/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setquotations', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("quotation hidden successfully")
                        } else {
                            toast.success("quotation got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async remove_quotation_item({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'remove_quotation_item' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/quotations/removeitem/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setquotations', response.data.res)
                        toast.success("quotation item removed successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_quotation( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_quotation' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/quotations/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setquotations', response.data.res)
                toast.success("quotation deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}