let routes = [

    {
        path: "/auth",
        props:true,
        component: () => import("@/views/auth/main.vue"),
        children: [
          {
            path: "login",
            name: "login",
            props:true,
            component: () => import("@/views/auth/login.vue"),
            meta: {
             title: 'Login to Your Dashboard',
             authRoute: true
            }
          },
          {
            path: "signup",
            name: "signup",
            props:true,
            component: () => import("@/views/auth/signup.vue"),
            meta: {
             title: 'Create an account',
             authRoute: true
            }
          },

          {
            path: "verify_device",
            name: "verify_device",
            props:true,
            component: () => import("@/views/auth/verify_device.vue"),
            meta: {
             title: 'Verify Your Device',
             authRoute: true
            }
          },

          {
            path: "verify_account/:number",
            name: "verify_account",
            props:true,
            component: () => import("@/views/auth/verify_account.vue"),
            meta: {
             title: 'Verify Account',
             authRoute: true
            }
          },
        ]
    },

]
export default routes;