export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        shopitems :'', 
        shopitem :'', 
    },
    
    getters : {
        shopitems: (state )=> state.shopitems,
        shopitem: (state )=> state.shopitem,
       
    },


    mutations : { 
        setshopitems :(state,shopitems ) => (state.shopitems = shopitems), 
        setshopitem :(state,shopitem ) => (state.shopitem = shopitem), 
    },

    actions : {

        async create_shopitem( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_shopitem')
            return new Promise((resolve, reject) => {
            axios.post("/web/shops/items/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshopitems', response.data.res)
                toast.success("shopitem added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_shopitems( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_shopitems')
            return new Promise((resolve, reject) => {
            axios.post("/web/shops/items", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshopitems', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_shopitem( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_shopitem' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/shops/items/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshopitem', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_shopitem( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_shopitem' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/shops/items/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshopitems', response.data.res)
                toast.success("shopitem updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_shopitem_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_shopitem_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/shops/items/updateimage/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshopitems', response.data.res)
                toast.success("shopitem's banner image updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_item_visibility({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/web/shops/items/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setshopitems', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("shopitem hidden successfully")
                        } else {
                            toast.success("shopitem got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_shopitem( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_shopitem' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/shops/items/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshopitems', response.data.res)
                toast.success("shopitem deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}