export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        activitylogs :'', 
        activitylog :'', 
    },
    
    getters : {
        activitylogs: (state )=> state.activitylogs,
        activitylog: (state )=> state.activitylog,
       
    },


    mutations : { 
        setactivitylogs :(state,activitylogs ) => (state.activitylogs = activitylogs), 
        setactivitylog :(state,activitylog ) => (state.activitylog = activitylog), 
    },

    actions : {

        async create_activitylog( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_activitylog')
            return new Promise((resolve, reject) => {
            axios.post("/manager/activity_logs/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivitylogs', response.data.res)
                toast.success("activitylog added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_activitylogs( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_activitylogs')
            return new Promise((resolve, reject) => {
            axios.post("/manager/activity_logs?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivitylogs', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_my_activitylogs( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_activitylogs')
            return new Promise((resolve, reject) => {
            axios.post("/manager/activity_logs/mine?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivitylogs', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_activitylog( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_activitylog' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/activity_logs/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivitylog', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_activitylog( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_activitylog' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/activity_logs/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivitylogs', response.data.res)
                toast.success("activitylog updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_activitylog_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/activity_logs/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setactivitylogs', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("activitylog hidden successfully")
                        } else {
                            toast.success("activitylog got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_activitylog( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_activitylog' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/activity_logs/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivitylogs', response.data.res)
                toast.success("Activitylog deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}