import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import '../public/assets/css/tailwind.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
import VueHtmlToPaper from 'vue-html-to-paper'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import moment from 'moment'

library.add(far);
library.add(fab);
library.add(fas);
dom.watch();

const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
    ],
    styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
  }

const app =  createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(moment).use(store).use(Toast).use(router).use(VueHtmlToPaper, options)


app.config.globalProperties.$filters = {
    timeAgo(date) {
    return moment(date).fromNow()
    },

    dateonly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    dateonlywithday(date) {
        return moment(date).format("DD/MM/YYYY - dddd")
    },


    datetime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },

    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
}
app.mount('#app')