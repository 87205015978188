import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import requisitions  from './modules/requisitions'
import invoices from './modules/invoices';
import profomas from './modules/profomas';
import quotations from './modules/quotations';
import receipts from './modules/receipts';
import income from './modules/income';
import clients from './modules/clients';
import items from './modules/items';
import services from './modules/services';
import users from './modules/users';
import deposits from './modules/deposits';
import expenses from './modules/expenses';
import stores from './modules/stores';
import management from './modules/management';
import activities from './modules/activities';
import activitylogs from './modules/activitylogs';
export default createStore({
  modules: {
    auth, 
    utils,
    receipts,
    invoices,
    profomas,
    quotations,
    requisitions,
    income,
    clients,
    items,
    services,
    users,
    deposits,
    services,
    expenses,
    stores,
    management,
    activities,
    activitylogs
  },
    
  plugins: [createPersistedState(
    {
      key : 'smarttraderuganda.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
