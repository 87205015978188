<template>
      <router-view></router-view>
</template>

<script>
  import { initFlowbite } from 'flowbite'
  export default {
    mounted(){
      initFlowbite()
    }
  }
</script>

<style>
  .active{
    @apply text-blue-600 font-semibold border-blue-600 dark:text-blue-500 dark:border-blue-500;
  }
  .inactive{
    @apply border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-200;
  }
</style>


