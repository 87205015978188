import store from '@/store'

const adminguard = (to, from, next) => {
  if(localStorage.getItem('smarttraderauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id ){
      return next()
  }else{
      return next({ name: 'login' })
  }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        meta: {appRoute: true},
        children: [
          // ============================================== Dashboard ============================================
          {
            path: "/",
            name: 'dashboard',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/dashboard/main.vue"),
            title:'Dashboard',
            meta: {appRoute: true},
          },


          // ============================================== Notifications ============================================
          {
            path: "/notifications",
            name: 'notifications',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/notifications/main.vue"),
            meta: {
              appRoute: true
            },
            children: [
              {
                path: "notification",
                name: 'notification',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/notifications/noti_preview.vue"), 
                meta: {
                  title: 'Notification',
                  appRoute: true
                },
              },
              {
                path: "all-notifications",
                name: 'all_notifications',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/notifications/notifications.vue"), 
                meta: {
                  title: 'List Of All Notifications',
                  appRoute: true
                },
              },
            ],
          },


          // ============================================== Quotations ============================================
          {
            path: "/quotations",
            name: 'quotations',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/quotations/main.vue"),
            children: [
              {
                path: "create-quotation",
                name: 'create_quotation',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/quotations/create_quot.vue"),
                meta: {
                  title: 'Generate Quotation',
                  appRoute: true
                },
              },
              {
                path: ":id",
                name: 'quotation',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/quotations/quot.vue"),
                meta: {
                  title: 'Quotation',
                  appRoute: true
                },
              },
              {
                path: ":id/edit",
                name: 'edit_quotation',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/quotations/edit_quotation.vue"),
                meta: {
                  title: 'Edit Quotation',
                  appRoute: true
                },
              },
              {
                path: "all-quotations",
                name: 'all_quotations',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/quotations/all_quots.vue"),
                meta: {
                  title: 'List Of All Quotations',
                  appRoute: true
                },
              },


              {
                path: "printorsend/:id",
                name: 'printorsendtoclient',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/quotations/printorsendtoclient.vue"),
                meta: {
                  title: 'Edit Quotation',
                  appRoute: true
                },
              },
              
            ],
          },


          // ============================================== Proforma ============================================
          {
            path: "/proforma",
            name: 'proformas',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/proforma/main.vue"),
            children: [
              {
                path: "generate-proforma",
                name: 'create_proforma',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/proforma/create_proforma.vue"),
                meta: {
                  title: 'Generate Proforma Invoice',
                  appRoute: true
                },
              },
              {
                path: "all_proforma",
                name: 'all_proformas',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/proforma/all_proforma.vue"),
                meta: {
                  title: 'List Of All Proforma Invoices',
                  appRoute: true
                },
              },
              {
                path: ":id",
                name: 'proforma',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/proforma/proforma.vue"),
                meta: {
                  title: 'Proforma Invoice',
                  appRoute: true
                },
              },
              {
                path: ":id/edit",
                name: 'edit_proforma',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/proforma/edit_proforma.vue"),
                meta: {
                  title: 'Edit Proforma',
                  appRoute: true
                },
              },


              {
                path: "printorsend/:id",
                name: 'printorsendtoclient_proforma',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/proforma/printorsendtoclient.vue"),
                meta: {
                  title: 'Edit Quotation',
                  appRoute: true
                },
              },
            ],
          },


          // ============================================== Requisitions ============================================
          {
            path: "/requisitions",
            name: 'requisitions',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/requisitions/main.vue"),
            children: [
              {
                path: ":id",
                name: 'requisition',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/requisition.vue"),
                meta: {
                  title: 'Requisition',
                  appRoute: true
                }
              },
              {
                path: ":id/print",
                name: 'print',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/print.vue"),
                meta: {
                  title: 'Print Requisition',
                  appRoute: true
                }
              },
              {
                path: "create-requisition",
                name: 'create_requisition',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/create_req.vue"),
                meta: {
                  title: 'Generate Requisition',
                  appRoute: true
                }
              },
              {
                path: "admin-requisitions",
                name: 'admin_requisitions',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/all_req.vue"),
                meta: {
                  title: 'List Of All Admin Requisitions',
                  appRoute: true
                }
              },
              {
                path: "my-requisitions",
                name: 'my_requisitions',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/my_requisitions.vue"),
                meta: {
                  title: 'List Of All My Requisitions',
                  appRoute: true
                }
              },
              {
                path: "requisitions-history",
                name: 'history',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/history.vue"),
                meta: {
                  title: 'List Of All My Requisitions History',
                  appRoute: true
                }
              },
              {
                path: "accounts-requisitions",
                name: 'accounts_requisitions',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/approved.vue"),
                meta: {
                  title: 'List Of All Approved Requisitions',
                  appRoute: true
                }
              },
              {
                path: ":id/edit",
                name: 'edit_requisition',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/edit_requisition.vue"),
                meta: {
                  title: 'Edit Requisition',
                  appRoute: true
                },
              },

              {
                path: "accounts-requisitions/print",
                name: 'accounts_requisitions_prints',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/requisitions/item_print_outs.vue"),
                meta: {
                  title: 'List Of All Approved Requisitions',
                  appRoute: true
                }
              },
            ],
          },


          // ============================================== Receipts ============================================
          {
            path: "/receipts",
            name: 'receipts',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/receipts/main.vue"),
            children: [
              {
                path: "all-receipts",
                name: 'all_receipts',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/receipts/all_receipts.vue"),
                meta: {
                  title: 'List Of All Receipts',
                  appRoute: true
                }
              },
              {
                path: "generate-receipt",
                name: 'create_receipt',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/receipts/create_receipt.vue"),
                meta: {
                  title: 'Generate Receipt',
                  appRoute: true
                }
              },
              {
                path: ":id",
                name: 'receipt',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/receipts/receipt.vue"),
                meta: {
                  title: 'Receipts',
                  appRoute: true
                }
              },
              {
                path: ":id/edit",
                name: 'edit_receipt',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/receipts/edit_receipt.vue"),
                meta: {
                  title: 'Edit Receipt',
                  appRoute: true
                },
              },

              {
                path: "printorsend/:id",
                name: 'printorsendtoclient_receipts',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/receipts/printorsendtoclient.vue"),
                meta: {
                  title: 'Edit Quotation',
                  appRoute: true
                },
              },
            ],
          },



          // ============================================== Invoices ============================================
          {
            path: "/invoices",
            name: 'invoices',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/invoices/main.vue"),
            children: [
              {
                path: "all-invoices",
                name: 'all_invoices',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/invoices/all_invoices.vue"),
                meta: {
                  title: 'List Of All Invoices',
                  appRoute: true
                },
              },
              {
                path: ":id",
                name: 'invoice',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/invoices/invoice.vue"),
                meta: {
                  title: 'Invoice',
                  appRoute: true
                },
              },
              {
                path: "generate-invoice",
                name: 'create_invoice',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/invoices/create_invoice.vue"),
                meta: {
                  title: 'Generate Invoice',
                  appRoute: true
                },
              },
              {
                path: ":id/edit",
                name: 'edit_invoice',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/invoices/edit_invoice.vue"),
                meta: {
                  title: 'Edit Invoice',
                  appRoute: true
                },
              },

              {
                path: "printorsend/:id",
                name: 'printorsendtoclient_invoices',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/invoices/printorsendtoclient.vue"),
                meta: {
                  title: 'Edit Quotation',
                  appRoute: true
                },
              },
            ],
          },


          // ============================================== Clients ============================================
          {
            path: "/clients",
            name: 'clients',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/clients/main.vue"),
            children: [
              {
                path: "create-client",
                name: 'create_client',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/clients/create_client.vue"),
                meta: {
                  title: 'Create Client',
                  appRoute: true
                },
              },
              {
                path: "all-clients",
                name: 'all_clients',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/clients/all_clients.vue"),
                meta: {
                  title: 'List Of All Clients',
                  appRoute: true
                },
              },
              {
                path: ":id/edit",
                name: 'edit_client',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/clients/edit_client.vue"),
                meta: {
                  title: 'Edit Client',
                  appRoute: true
                },
              },
              {
                path: "potential-clients",
                name: 'potential_clients',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/clients/potential_clients.vue"),
                meta: {
                  title: 'Potential Clients',
                  appRoute: true
                },
              },
            ],
          },


          // ============================================== Accounts ============================================
          {
            path: "accounts",
            name: 'accounts',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/accounts/main.vue"),
            children: [
              {
                path: "deposits",
                name: 'deposits',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/accounts/deposits.vue"),
                meta: {
                  title: 'Deposits',
                  appRoute: true
                }
              },

              {
                path: "deposits/:id",
                name: 'deposit_details',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/accounts/deposit_details.vue"),
                meta: {
                  title: 'Deposit Details',
                  appRoute: true
                }
              },
              {
                path: "income",
                name: 'income',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/accounts/income.vue"),
                meta: {
                  title: 'Income',
                  appRoute: true
                }
              },
              {
                path: "expenses",
                name: 'expenses',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/accounts/expenses.vue"),
                meta: {
                  title: 'Expenses',
                  appRoute: true
                }
              },
            ],
          },


          // ============================================== Reports ============================================
          {
            path: "reports",
            name: 'reports',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/Reports/main.vue"),
            children: [
              {
                path: "income-reports",
                name: 'income_reports',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/Reports/income.vue"),
                meta: {
                  title: 'Income Reports',
                  appRoute: true
                }
              },
              {
                path: "expense-reports",
                name: 'expense_reports',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/Reports/expense.vue"),
                meta: {
                  title: 'Expense Reports',
                  appRoute: true
                }
              },
              {
                path: "balance-sheet",
                name: 'balance_sheet',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/Reports/transaction_stm.vue"),
                meta: {
                  title: 'Balance Sheet',
                  appRoute: true
                }
              },
            ],
          },


          // ============================================== stores ============================================
          {
            path: "store",
            name: 'store',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/stores/main.vue"),
            children: [
              {
                path: "inventory",
                name: 'inventory',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/stores/inventory.vue"),
                meta: {
                  title: 'List Of Inventory Items',
                  appRoute: true
                }
              },
              {
                path: "tracker",
                name: 'tracker',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/stores/track.vue"),
                meta: {
                  title: 'Track Items',
                  appRoute: true
                }
              },
            ],
          },


          // =========================================== management =======================================================
          {
            path: "/management",
            name: 'management',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/management/main.vue"),
            children: [
              {
                path: "all_roles",
                name: 'all_roles',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/management/roles.vue"),
                meta: {
                  title: 'List of All Roles',
                  approute: true
                }
              },
              {
                path: "all_restrictions",
                name: 'all_restrictions',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/management/restrictions.vue"),
                meta: {
                  title: 'List of All Restrictions',
                  approute: true
                }
              },
              {
                path: "all_positions",
                name: 'all_positions',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/management/positions.vue"),
                meta: {
                  title: 'List of All Positions',
                  approute: true
                }
              },
              {
                path: "all_users",
                name: 'all_users',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/management/users.vue"),
                meta: {
                  title: 'List of All Users',
                  approute: true
                }
              },
              {
                path: "create_user",
                name: 'create_user',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/management/create_user.vue"),
                meta: {
                  title: 'Create New User',
                  approute: true
                }
              },
              {
                path: "profile",
                name: 'profile',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/management/profile.vue"),
                meta: {
                  title: 'My profile',
                  approute: true
                }
              },
            ],
          },


          // =========================================== files =======================================================
          {
            path: "/files",
            name: 'files',
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/files/main.vue"),
            children: [
              {
                path: "all_files",
                name: 'all_files',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/files/files.vue"),
                meta: {
                  title: 'List of All files',
                  approute: true
                }
              },
            ],
          },


// =========================================== attendance =======================================================
          {
            path: "/activities",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/activities/main.vue"),
            children: [
              {
                path: "mine",
                name: 'activity_logs',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/activities/activity_logs.vue"),
                meta: {
                  title: 'My Activity Logs',
                  approute: true
                }
              },

              {
                path: "report",
                name: 'activity_logs_report',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/activities/activity_logs_report.vue"),
                meta: {
                  title: 'General Activity Log Report',
                  approute: true
                }
              },

              {
                path: "admin-activities",
                name: 'admin_activities',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/activities/admin_activities.vue"),
                meta: {
                  title: 'Admin Activities',
                  approute: true
                }
              },

              {
                path: "admin-activities/:id",
                name: 'admin_activity_details',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/activities/activity_details.vue"),
                meta: {
                  title: 'Admin Activity Details',
                  approute: true
                }
              },

              {
                path: "admin-activity-logs-report",
                name: 'admin_activity_logs_report',
                props: true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/activities/admin_activity_logs_report.vue"),
                meta: {
                  title: 'Activities Report',
                  approute: true
                }
              },
              
            ],
          },

          
        ]
    }
]
export default routes;