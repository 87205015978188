export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        authuser :null,
        authlocation : '',
        isAuth:'',
		returnvalue:'',
        userip:'',
        clientlocation:'',
        clientdevice:'',
        restrictions:'',
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        restrictions: (state )=> state.restrictions,
        authlocation: (state )=> state.authlocation,
		returnvalue: (state )=> state.returnvalue,
        isAuth: state => !!state.authuser,
        userip: (state )=> state.userip,
        clientlocation:(state )=> state.clientlocation,
        clientdevice:(state )=> state.clientdevice,
       
    },


    mutations : { 
        setauthuser :(state,authuser ) => (state.authuser = authuser), 
        setrestrictions :(state,restrictions ) => (state.restrictions = restrictions), 
        setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
        setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
        setreturnvalue :(state,returnvalue ) => (state.returnvalue = returnvalue),
        setuserip :(state,userip ) => (state.userip = userip),
        setclientlocation :(state,clientlocation ) => (state.clientlocation = clientlocation),
        setclientdevice :(state,clientdevice ) => (state.clientdevice = clientdevice),
    },

    actions : {


        getuseripaddress({ commit }){
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    commit('setuserip', ip)
                });
        },

        getuserlocation({ commit, getters }){
            console.log(getters.userip)
            const url = "https://ipapi.co/"+ getters.userip +"/json/";
            const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                a: 10,
                b: 20,
            }),
            };
            fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                commit('setclientlocation', data)
            });
        },



        get_user_device({commit}){

        },


        async send_login_code( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'send_login_code')
            return new Promise((resolve, reject) => {
            axios.post("/auth/send_login_code", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async verify_login_code( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'verify_login_code')
            return new Promise((resolve, reject) => {
            axios.post("/auth/verify_login_code", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('smarttraderauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    localStorage.setItem('userlocation', response.data.location)
                    commit('setauthuser', response.data.res)
                    commit('setauthlocation', response.data.location)
					resolve(response)
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async verify_access_device( { commit }, data) {
                    commit('seterror', '')
                    commit('setmsg', '')
                    commit('setloader', 'verify_access_device')
                    return new Promise((resolve, reject) => {
                    axios.post("/auth/verify_access_device", data)
                    .then(response => {	
                        commit('setloader', false)
                        if(response.data.res){
                            localStorage.setItem('smarttraderauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                            localStorage.setItem('userlocation', response.data.location)
                            commit('setauthuser', response.data.res)
                            commit('setauthlocation', response.data.location)
                            resolve(response)
                            toast.success(response.data.msg)
                        }
                        toast.success(response.data.msg)
                        resolve(response)
                    })
                    .catch(error=> {
                        commit('setloader', false)
                        if (error.response) {
                            if(error.response.data){
                                if(error.response.data.msg){
                                    commit('seterror', error.response.data.msg)
                                    toast.error(error.response.data.msg)
                                }else if(error.response.data.message){
                                    commit('seterrors', error.response.data.message)
                                }
                                
                            }
                        }
                        reject(error)
                    });
                    });	       
                },


            async verify_device_code( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'verify_device_code')
            return new Promise((resolve, reject) => {
            axios.post("/auth/verify_device_code", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('smarttraderauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    localStorage.setItem('userlocation', response.data.location)
                    commit('setauthuser', response.data.res)
                    commit('setrestrictions', response.data.restrictions)
                    commit('setauthlocation', response.data.location)
					resolve(response)
                    toast.success(response.data.msg)
                }else{
                    localStorage.removeItem('smarttraderauthtoken'+ store.getters.authuser.id)
                    localStorage.removeItem('userlocation')
                    localStorage.clear();
                    commit('setauthuser', '') 
                }
                toast.success(response.data.msg)
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async getauthuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'getauthuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/authuser", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('smarttraderauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    localStorage.setItem('userlocation', response.data.location)
                    commit('setauthuser', response.data.res)
                    commit('setrestrictions', response.data.restrictions)
                    commit('setauthlocation', response.data.location)
					resolve(response)
                }else{

                }
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async signupuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'signupuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/register", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('smarttraderauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    localStorage.setItem('userlocation', response.data.location)
                    commit('setauthuser', response.data.res)
                    commit('setauthlocation', response.data.location)
                    commit('setmsg', 'Welcome Back '+ response.data.res.name)
					resolve(response)
                }else{

                }
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async loginuser({commit}, data){
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'loginuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/login", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('smarttraderauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    localStorage.setItem('userlocation', response.data.location)
                    commit('setauthuser', response.data.res)
                    commit('setauthlocation', response.data.location)
					resolve(response)
                }else{

                }
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	
        },

        async logoutuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'logoutuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/logoutuser", data)
            .then(response => {	
                commit('setloader', false)
                    localStorage.removeItem('smarttraderauthtoken'+ store.getters.authuser.id)
                    localStorage.removeItem('userlocation')
                    localStorage.clear();
                    commit('setauthuser', '')
                    commit('setauthlocation', '')
                    commit('setmsg', 'Successfully Logged Out')
                    resolve(response);
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }

                    localStorage.removeItem('smarttraderauthtoken'+ store.getters.authuser.id)
                    localStorage.removeItem('userlocation')
                    localStorage.clear();
                    commit('setauthuser', '')
                    commit('setauthlocation', '')
                    commit('setmsg', 'Successfully Logged Out')
                }
                reject(error)
            });
            });	       
        },


        async updateaccount( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'updateaccount')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updateaccount", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setauthuser', response.data.res)
                    toast.success("Account information updated successfully")
                    resolve(response)
                }else{
    
                }
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async updateprofileimage( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'updateprofileimage')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updateprofilepic", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setauthuser', response.data.res)
                    toast.success("Profile image updated successfully")
                    resolve(response)
                }else{
    
                }
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async changepassword( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'changepassword')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updatepassword", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setauthuser', response.data.res)
                    toast.success("Password changed successfully")
                    resolve(response)
                }else{
    
                }
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async deleteuseraccount( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'deleteuseraccount')
            return new Promise((resolve, reject) => {
            axios.post("/auth/deleteaccount", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setauthuser', response.data.res)
                    resolve(response)
                }else{
    
                }
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    },

    
}













