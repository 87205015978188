export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        requisitions :'', 
        requisition :'', 
        approvedrequisitions :'', 
    },
    
    getters : {
        requisitions: (state )=> state.requisitions,
        requisition: (state )=> state.requisition,
        approvedrequisitions: (state )=> state.approvedrequisitions,
       
    },


    mutations : { 
        setrequisitions :(state,requisitions ) => (state.requisitions = requisitions), 
        setrequisition :(state,requisition ) => (state.requisition = requisition), 
        setapprovedrequisitions :(state,approvedrequisitions ) => (state.approvedrequisitions = approvedrequisitions), 
    },

    actions : {

        async create_requisition( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_requisition')
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                toast.success("requisition added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_requisitions( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_requisitions')
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_approved_requisitions( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_approved_requisitions')
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions/approved?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setapprovedrequisitions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_approved_requisitions_to_print( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_approved_requisitions')
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions/approved/print?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setapprovedrequisitions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_requisition( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_requisition' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_requisition( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_requisition' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                toast.success("requisition updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_requisition_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/requisitions/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setrequisitions', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("requisition hidden successfully")
                        } else {
                            toast.success("requisition got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },


        async requisition_item_approval({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'requisition_item_approval' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/requisitions/itemsapproval/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setrequisitions', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("requisition hidden successfully")
                        } else {
                            toast.success("requisition got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },


        async requisition_approval({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'requisition_approval' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/requisitions/approval/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setrequisitions', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("requisition hidden successfully")
                        } else {
                            toast.success("requisition got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_requisition( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_requisition' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                toast.success("requisition deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_requisition_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_requisition_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/requisitions/deleteitem/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                toast.success("requisition item deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}