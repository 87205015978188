export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        services :'', 
        service :'', 
    },
    
    getters : {
        services: (state )=> state.services,
        service: (state )=> state.service,
        need: (state )=> state.need,
       
    },


    mutations : { 
        setservices :(state,services ) => (state.services = services), 
        setservice :(state,service ) => (state.service = service),
    },

    actions : {

        // ================================================= Services =================================================
        async create_service( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_service')
            return new Promise((resolve, reject) => {
            axios.post("/web/services/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.res)
                toast.success("service added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_services( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_services')
            return new Promise((resolve, reject) => {
            axios.post("/web/services", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_service( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_service' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/services/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservice', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_service( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_service' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/services/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.res)
                toast.success("service updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_banner_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_banner_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/services/updatebannerimage/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.res)
                toast.success("service's banner image updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_service_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_service_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/services/updateimage/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.res)
                toast.success("service's banner image updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_service_visibility({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/web/services/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setservices', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("Service hidden successfully")
                        } else {
                            toast.success("Service got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_service( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_service' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/services/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.res)
                toast.success("service deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}