export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        clients :'', 
        client :'', 
        potentialclients :'', 
        potentialclient :'', 
    },
    
    getters : {
        clients: (state )=> state.clients,
        client: (state )=> state.client,
        potentialclients: (state )=> state.potentialclients,
        potentialclient: (state )=> state.potentialclient,
       
    },


    mutations : { 
        setclients :(state,clients ) => (state.clients = clients), 
        setclient :(state,client ) => (state.client = client), 
        setpotentialclients :(state,potentialclients ) => (state.potentialclients = potentialclients), 
        setpotentialclient :(state,potentialclient ) => (state.potentialclient = potentialclient), 
    },

    actions : {
        // ==================================================== clients ===============================================
        async create_client( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_client')
            return new Promise((resolve, reject) => {
            axios.post("/web/clients/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setclients', response.data.res)
                toast.success("client added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_clients( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_clients')
            return new Promise((resolve, reject) => {
            axios.post("/web/clients?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setclients', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_client( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_client' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/clients/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setclient', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_client( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_client' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/clients/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setclients', response.data.res)
                toast.success("client updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_client_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_client_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/clients/updateimage/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setclients', response.data.res)
                toast.success("client's image updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_client_visibility({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/web/clients/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setclients', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("client hidden successfully")
                        } else {
                            toast.success("client got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_client( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_client' + id)
            return new Promise((resolve, reject) => {
            axios.post("/web/clients/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setclients', response.data.res)
                toast.success("client deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        // ========================================== potential clients ==================================================
        async create_potentialclient( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_potentialclient')
            return new Promise((resolve, reject) => {
            axios.post("/manager/potentialclients/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpotentialclients', response.data.res)
                toast.success("potentialclient added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_potentialclients( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_potentialclients')
            return new Promise((resolve, reject) => {
            axios.post("/manager/potentialclients", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpotentialclients', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_potentialclient( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_potentialclient' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/potentialclients/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpotentialclient', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_potentialclient( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_potentialclient' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/potentialclients/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpotentialclients', response.data.res)
                toast.success("potentialclient updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_potentialclient_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_potentialclient_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/potentialclients/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setpotentialclients', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("potentialclient hidden successfully")
                        } else {
                            toast.success("potentialclient got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_potentialclient( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_potentialclient' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/potentialclients/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpotentialclients', response.data.res)
                toast.success("potentialclient deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}