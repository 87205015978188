export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        invoices :'', 
        invoice :'', 
    },
    
    getters : {
        invoices: (state )=> state.invoices,
        invoice: (state )=> state.invoice,
       
    },


    mutations : { 
        setinvoices :(state,invoices ) => (state.invoices = invoices), 
        setinvoice :(state,invoice ) => (state.invoice = invoice), 
    },

    actions : {

        async create_invoice( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_invoice')
            return new Promise((resolve, reject) => {
            axios.post("/manager/invoices/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinvoices', response.data.res)
                toast.success("invoice added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_invoices( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_invoices')
            return new Promise((resolve, reject) => {
            axios.post("/manager/invoices?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinvoices', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_invoice( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_invoice' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/invoices/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinvoice', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_invoice( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_invoice' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/invoices/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinvoices', response.data.res)
                toast.success("invoice updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_invoice_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/invoices/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setinvoices', response.data.res)
                        if (response.data.status == 0) {
                            toast.success("invoice hidden successfully")
                        } else {
                            toast.success("invoice got alive successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async remove_invoice_item({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'remove_invoice_item' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/manager/invoices/removeitem/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setinvoices', response.data.res)
                        toast.success("invoice item removed successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_invoice( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_invoice' + id)
            return new Promise((resolve, reject) => {
            axios.post("/manager/invoices/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinvoices', response.data.res)
                toast.success("invoice deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}